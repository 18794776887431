import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BackGlossary from '../../../../components/BackGlossary';
import BannerRussel from '../../../../components/BannerRussel';

const GlossaryPageContent = () => {
  return (
    <div className="md:px-5 max-w-[1285px]">
      <div className="mb-8 text-xl font-bold text-left md:text-2xl">Dormant account</div>
      <p className="mb-12 text-lg text-left">
        A dormant account is an identity which exists, but has not been used for a period of time. The period of time
        required to quality an account as dormant may vary. For example, as you may only login to the DMV twice a year
        at most, the DMV could define a dormant account being “an account that hasn’t been logged into for more than a
        year.”<br></br>
        <br></br> A free email provider may consider a user has abandoned their service, and may cancel their free email
        account if it hasn’t been logged into for a shorter period of dormancy, for example, six months.
      </p>
    </div>
  );
};

const DormantAccount = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `Dormant account`;
  const description = `Explore our site to discover detailed information about ${title}`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={description} />
          </div>
        </section>

        <section className="pb-8">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <GlossaryPageContent />
            </div>
          </div>
        </section>
      </BackgroundImage>
      <section className="pb-16">
        <BannerRussel
          title="Curious how Trustle works?"
          description={'Get an inside look at Trustle. Schedule a demo with us today.'}
        />
      </section>
    </Layout>
  );
};
export default DormantAccount;
